import * as React from "react";
import "./devis.css";
import Layout from "../layouts/layout";
import { useState } from "react";
import axios from 'axios';
import favicon from "../images/favico.ico";
import { Helmet } from "react-helmet";
import { navigate } from 'gatsby';




const DevisPage = () => {
    const [devis, setDevis] = useState({});
    const [alert, setAlert] = useState(false);
    const [alertDanger, setAlertDanger] = useState(false);


    function handledInputData(event) {

        const name = event.target.name;
        const value = event.target.value;
        setDevis(devis => ({ ...devis, [name]: value }))


    }

    const handleSubmit = (event) => {
        event.preventDefault();


        const formData = new FormData();
        formData.append('image', devis.file);
        formData.append('image1', devis.file1);
        formData.append('image2', devis.file2);
        formData.append('nom', devis.nom);
        formData.append('prenom', devis.prenom);
        formData.append('tel', devis.tel);
        formData.append('email', devis.email);
        formData.append('inter1', devis.typeIntervention1);
        formData.append('inter2', devis.typeIntervention2);
        formData.append('date', devis.dateintervention);
        formData.append('pays', devis.pays);
        formData.append('desc', devis.desc);


        let url = "https://www.medica-tour.fr/mail/sendMail.php/";
        axios.post(url, formData, {
        })
            .then(res => {
                setDevis({});
                document.getElementById("form").reset();
                navigate("/success-2/")
                // if (window) {
                //     window.location.href = "/success-2/";
                // }
            }).catch(function (error) {
                console.log(error);
                setAlertDanger(true);
            });
    }

    return (
        <>
            <Layout>

                <Helmet htmlAttributes={{
                    lang: 'fr-FR',
                }}>
                    <link rel="icon" type="image/x-icon" href={favicon} />
                    <title>Obtenez un devis pour votre séjour médical en Tunisie</title>
                    <meta name="description" content="Notre équipe expérimentée vous accompagne dans toutes les étapes de votre voyage de santé, de l'organisation  jusqu'à votre prise en charge par des professionnels de la santé de qualité." />
                    <meta name="robots" content="index, follow" />
                    <link data-react-helmet="true" rel="canonical" href="https://www.medica-tour.fr/devis/" />
                    <meta name="msvalidate.01" content="BF6180D2B9843FD051D8456A095B9C38" />
                    <script async src="https://www.googletagmanager.com/gtag/js?id=AW-10925153812"></script>
    <script>
      {`
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', 'AW-10925153812');
      `}
    </script>
                </Helmet>

                <div className="container-fluid service pt-5 pb-5">
                    <div className="container intern-page-style">
                        <h1 className="service-title">  
                            Demande de devis
                        </h1>

                    </div>
                </div>

                <div className="container-fluid">

                    <div class="container pt-5 mb-5">

                        {/* <form onSubmit={handleSubmit} id="form">
                            <div class="mb-3">
                                <div className="row">
                                    <div className="col-sm-12 col-md-6 mt-3">
                                        <label for="nom" class="form-label fw-bolder">Nom :<span class="text-danger">*</span></label>
                                        <input onChange={handledInputData} type="text" class="form-control" name="nom" id="nom" aria-describedby="emailHelp" required />
                                    </div>

                                    <div className="col-sm-12 col-md-6 mt-3">
                                        <label for="prenom" class="form-label fw-bolder">Prénom  :<span class="text-danger">*</span></label>
                                        <input onChange={handledInputData} type="text" class="form-control" name="prenom" id="prenom" aria-describedby="emailHelp" required />
                                    </div>


                                </div>

                                <div className="row">
                                    <div className="col-sm-12 col-md-6 mt-3">
                                        <label for="tel" class="form-label fw-bolder">Téléphone :<span class="text-danger">*</span></label>
                                        <input onChange={handledInputData} type="text" class="form-control" name="tel" id="tel" aria-describedby="emailHelp" required />
                                    </div>

                                    <div className="col-sm-12 col-md-6 mt-3">
                                        <label for="email" class="form-label fw-bolder">Email  :<span class="text-danger">*</span></label>
                                        <input onChange={handledInputData} type="email" class="form-control" id="email" name="email" aria-describedby="emailHelp" required />
                                    </div>


                                </div>

                                <div className="row">
                                    <div className="col-sm-12 col-md-6 mt-3">
                                        <label for="typeIntervention1" class="form-label fw-bolder">Type d'interventions 1 :<span class="text-danger">*</span></label>
                                        <select onChange={handledInputData} required class="form-select" id="typeIntervention1" name='typeIntervention1'>
                                            <option value="">Choisir une intervention</option>
                                            <option value="Sleeve gastrique">Sleeve gastrique</option>
                                            <option value="Anneau gastrique">Anneau gastrique </option>
                                            <option value="By-pass gastrique">By-pass gastrique</option>
                                            <option value="Augmentation mammaire">Augmentation mammaire</option>
                                            <option value="Lifting des seins">Lifting des seins</option>
                                            <option value="Lipofilling mammaire">Lipofilling mammaire</option>
                                            <option value="Reduction mammaire">Reduction mammaire</option>
                                            <option value="Implant dentaire">Implant dentaire </option>
                                            <option value="Facette dentaire">Facette dentaire</option>
                                            <option value="Blachimment des dents">Blachimment des dents</option>
                                            <option value="Abdominoplastie">Abdominoplastie</option>
                                            <option value="Mini abdominoplastie">Mini abdominoplastie</option>
                                            <option value="Lifting des bras">Lifting des bras</option>
                                            <option value="Body lift">Body lift</option>
                                            <option value="Liposuccion">Liposuccion</option>
                                            <option value="Lifting des cuisses">Lifting des cuisses</option>
                                            <option value="Augmentation des mollets">Augmentation des mollets</option>
                                            <option value="Augmentation des fesses(implant)">Augmentation des fesses(implant)</option>
                                            <option value="Lipofilling des fesses">Lipofilling des fesses</option>
                                            <option value="Blepharoplastie">Blepharoplastie</option>
                                            <option value="Genioplastie">Genioplastie</option>
                                            <option value="Lifting visage">Lifting visage</option>
                                            <option value="Mini lifting visage">Mini lifting visage</option>
                                            <option value="Otoplastie">Otoplastie</option>
                                            <option value="Rhinoplastie">Rhinoplastie</option>
                                            <option value="Nymphoplastie">Nymphoplastie</option>
                                            <option value="Penoplastie">Penoplastie</option>
                                            <option value="Vaginoplastie">Vaginoplastie</option>
                                            <option value="Biopsie testiculaire">Biopsie testiculaire</option>
                                            <option value="Fecondation in vitro">Fecondation in vitro</option>
                                            <option value="Insemination artificielle">Insemination artificielle</option>
                                            <option value="Greffe cheveux">Greffe cheveux</option>
                                            <option value="Greffe de la barbe">Greffe de la barbe</option>
                                            <option value="Greffe des sourcils">Greffe des sourcils</option>
                                            <option value="Traitement Laser">Traitement Laser</option>
                                            <option value="Mesobotox">Mesobotox</option>
                                            <option value="PRP">PRP</option>
                                            <option value="Rhinoplastie Médicale">Rhinoplastie Médicale</option>
                                            <option value="Amincissement">Amincissement</option>
                                            <option value="Fils Tenseurs">Fils Tenseurs</option>
                                            <option value="Mésothérapie">Mésothérapie</option>
                                            <option value="Toxine Botulique">Toxine Botulique</option>
                                        </select>
                                    </div>

                                    <div className="col-sm-12 col-md-6 mt-3">
                                        <label for="typeIntervention2" class="form-label fw-bolder">Type d'interventions 2  :</label>
                                        <select onChange={handledInputData} class="form-select" id="typeIntervention2" name='typeIntervention2'>
                                            <option value="">Choisir une intervention</option>
                                            <option value="Sleeve gastrique">Sleeve gastrique</option>
                                            <option value="Anneau gastrique">Anneau gastrique </option>
                                            <option value="By-pass gastrique">By-pass gastrique</option>
                                            <option value="Augmentation mammaire">Augmentation mammaire</option>
                                            <option value="Lifting des seins">Lifting des seins</option>
                                            <option value="Lipofilling mammaire">Lipofilling mammaire</option>
                                            <option value="Reduction mammaire">Reduction mammaire</option>
                                            <option value="Implant dentaire">Implant dentaire </option>
                                            <option value="Facette dentaire">Facette dentaire</option>
                                            <option value="Blachimment des dents">Blachimment des dents</option>
                                            <option value="Abdominoplastie">Abdominoplastie</option>
                                            <option value="Mini abdominoplastie">Mini abdominoplastie</option>
                                            <option value="Lifting des bras">Lifting des bras</option>
                                            <option value="Body lift">Body lift</option>
                                            <option value="Liposuccion">Liposuccion</option>
                                            <option value="Lifting des cuisses">Lifting des cuisses</option>
                                            <option value="Augmentation des mollets">Augmentation des mollets</option>
                                            <option value="Augmentation des fesses(implant)">Augmentation des fesses(implant)</option>
                                            <option value="Lipofilling des fesses">Lipofilling des fesses</option>
                                            <option value="Blepharoplastie">Blepharoplastie</option>
                                            <option value="Genioplastie">Genioplastie</option>
                                            <option value="Lifting visage">Lifting visage</option>
                                            <option value="Mini lifting visage">Mini lifting visage</option>
                                            <option value="Otoplastie">Otoplastie</option>
                                            <option value="Rhinoplastie">Rhinoplastie</option>
                                            <option value="Nymphoplastie">Nymphoplastie</option>
                                            <option value="Penoplastie">Penoplastie</option>
                                            <option value="Vaginoplastie">Vaginoplastie</option>
                                            <option value="Biopsie testiculaire">Biopsie testiculaire</option>
                                            <option value="Fecondation in vitro">Fecondation in vitro</option>
                                            <option value="Insemination artificielle">Insemination artificielle</option>
                                            <option value="Greffe cheveux">Greffe cheveux</option>
                                            <option value="Greffe de la barbe">Greffe de la barbe</option>
                                            <option value="Greffe des sourcils">Greffe des sourcils</option>
                                            <option value="Traitement Laser">Traitement Laser</option>
                                            <option value="Mesobotox">Mesobotox</option>
                                            <option value="PRP">PRP</option>
                                            <option value="Rhinoplastie Médicale">Rhinoplastie Médicale</option>
                                            <option value="Amincissement">Amincissement</option>
                                            <option value="Fils Tenseurs">Fils Tenseurs</option>
                                            <option value="Mésothérapie">Mésothérapie</option>
                                            <option value="Toxine Botulique">Toxine Botulique</option>
                                        </select>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-12 col-md-6 mt-3">
                                        <label for="dateintervention" class="form-label fw-bolder">Date souhaitée d'intervention:<span class="text-danger">*</span></label>
                                        <input onChange={handledInputData} type="date" min={new Date().toISOString().split("T")[0]} id="dateintervention" name='dateintervention' class="form-control" required />
                                    </div>

                                    <div className="col-sm-12 col-md-6 mt-3">
                                        <label for="pays" class="form-label fw-bolder">Pays:<span class="text-danger">*</span></label>
                                        <select onChange={handledInputData} required class="form-select" id="pays" name='pays'>
                                            <option value="">Choisir une pays</option>
                                            <option value="France">France</option>
                                            <option value="Afghanistan">Afghanistan</option>
                                            <option value="Åland Islands">Åland Islands</option>
                                            <option value="Albanie">Albanie</option>
                                            <option value="Algérie">Algérie</option>
                                            <option value="Samoa">Samoa</option>
                                            <option value="Andorre">Andorre</option>
                                            <option value="Angola">Angola</option>
                                            <option value="Anguilla">Anguilla</option>
                                            <option value="Antarctique">Antarctique</option>
                                            <option value="Antigua et Barbuda">Antigua et Barbuda</option>
                                            <option value="Argentine">Argentine</option>
                                            <option value="Arménie">Arménie</option>
                                            <option value="Aruba">Aruba</option>
                                            <option value="Australie">Australie</option>
                                            <option value="Autriche">Autriche</option>
                                            <option value="Azerbaïdjan">Azerbaïdjan</option>
                                            <option value="Bahamas">Bahamas</option>
                                            <option value="Bahrain">Bahrain</option>
                                            <option value="Bangladesh">Bangladesh</option>
                                            <option value="Barbade">Barbade</option>
                                            <option value="Belarus">Belarus</option>
                                            <option value="Belgique">Belgique</option>
                                            <option value="Belize">Belize</option>
                                            <option value="Bénin">Bénin</option>
                                            <option value="Bermuda">Bermuda</option>
                                            <option value="Bhutan">Bhutan</option>
                                            <option value="Bolivie">Bolivie</option>
                                            <option value="Bonaire, Saint-Eustache et Saba">Bonaire, Saint-Eustache et Saba</option>
                                            <option value="Bosnie-Herzégovine">Bosnie-Herzégovine</option>
                                            <option value="Botswana">Botswana</option>
                                            <option value="Île Bouvet">Île Bouvet</option>
                                            <option value="Brésil">Brésil</option>
                                            <option value="Territoire britannique de l'océan Indien">Territoire britannique de l'océan Indien</option>
                                            <option value="Brunéi Darussalam">Brunéi Darussalam</option>
                                            <option value="Bulgarie">Bulgarie</option>
                                            <option value="Burkina Faso">Burkina Faso</option>
                                            <option value="Burundi">Burundi</option>
                                            <option value="Cambodge">Cambodge</option>
                                            <option value="Cameroun">Cameroun</option>
                                            <option value="Canada">Canada</option>
                                            <option value="Cap-Vert">Cap-Vert</option>
                                            <option value="Îles Caïmans">Îles Caïmans</option>
                                            <option value="République centrafricaine">République centrafricaine</option>
                                            <option value="Tchad">Tchad</option>
                                            <option value="Chili">Chili</option>
                                            <option value="Chine">Chine</option>
                                            <option value="Île Christmas">Île Christmas</option>
                                            <option value="Îles Cocos (Keeling)">Îles Cocos (Keeling)</option>
                                            <option value="Colombie">Colombie</option>
                                            <option value="Comores">Comores</option>
                                            <option value="Congo">Congo</option>
                                            <option value="Congo">Congo, République démocratique du Congo</option>
                                            <option value="Îles Cook">Îles Cook</option>
                                            <option value="Costa Rica">Costa Rica</option>
                                            <option value="Côte d'Ivoire">Côte d'Ivoire</option>
                                            <option value="Croatie">Croatie</option>
                                            <option value="Cuba">Cuba</option>
                                            <option value="Curaçao">Curaçao</option>
                                            <option value="Chypre">Chypre</option>
                                            <option value="République tchèque">République tchèque</option>
                                            <option value="Danemark">Danemark</option>
                                            <option value="Djibouti">Djibouti</option>
                                            <option value="Dominique">Dominique</option>
                                            <option value="République dominicaine">République dominicaine</option>
                                            <option value="Équateur">Équateur</option>
                                            <option value="Égypte">Égypte</option>
                                            <option value="El Salvador">El Salvador</option>
                                            <option value="Guinée équatoriale">Guinée équatoriale</option>
                                            <option value="Érythrée">Érythrée</option>
                                            <option value="Estonie">Estonie</option>
                                            <option value="Éthiopie">Éthiopie</option>
                                            <option value="Îles Falkland (Malvinas)">Îles Falkland (Malvinas)</option>
                                            <option value="Îles Féroé">Îles Féroé</option>
                                            <option value="Fidji">Fidji</option>
                                            <option value="Finlande">Finlande</option>
                                            <option value="Guyane française">Guyane française</option>
                                            <option value="Polynésie française">Polynésie française</option>
                                            <option value="Terres australes françaises">Terres australes françaises</option>
                                            <option value="Gabon">Gabon</option>
                                            <option value="Gambie">Gambie</option>
                                            <option value="Géorgie">Géorgie</option>
                                            <option value="Allemagne">Allemagne</option>
                                            <option value="Ghana">Ghana</option>
                                            <option value="Gibraltar">Gibraltar</option>
                                            <option value="Grèce">Grèce</option>
                                            <option value="Groenland">Groenland</option>
                                            <option value="Grenade">Grenade</option>
                                            <option value="Guadeloupe">Guadeloupe</option>
                                            <option value="Guam">Guam</option>
                                            <option value="Guatemala">Guatemala</option>
                                            <option value="Guernesey">Guernesey</option>
                                            <option value="Guinée">Guinée</option>
                                            <option value="Guinée-Bissau">Guinée-Bissau</option>
                                            <option value="Guyane">Guyane</option>
                                            <option value="Haïti">Haïti</option>
                                            <option value="Île Heard et îles McDonald">Île Heard et îles McDonald</option>
                                            <option value="Saint-Siège">Saint-Siège (État de la Cité du Vatican)</option>
                                            <option value="Honduras">Honduras</option>
                                            <option value="Hong Kong">Hong Kong</option>
                                            <option value="Hongrie">Hongrie</option>
                                            <option value="Islande">Islande</option>
                                            <option value="Inde">Inde</option>
                                            <option value="Indonésie">Indonésie</option>
                                            <option value="Iran, République islamique d Iran">Iran, République islamique d Iran</option>
                                            <option value="Irak">Irak</option>
                                            <option value="Irlande">Irlande</option>
                                            <option value="Île de Man">Île de Man</option>
                                            <option value="Italie">Italie</option>
                                            <option value="Jamaïque">Jamaïque</option>
                                            <option value="Japon">Japon</option>
                                            <option value="Jersey">Jersey</option>
                                            <option value="Jordanie">Jordanie</option>
                                            <option value="Kazakhstan">Kazakhstan</option>
                                            <option value="Kenya">Kenya</option>
                                            <option value="Kiribati">Kiribati</option>
                                            <option value="Corée">Corée, République populaire démocratique de Corée</option>
                                            <option value="République de Corée">Corée, République de Corée</option>
                                            <option value="Koweït">Koweït</option>
                                            <option value="Kirghizistan">Kirghizistan</option>
                                            <option value="LAO">République démocratique populaire lao</option>
                                            <option value="Lettonie">Lettonie</option>
                                            <option value="Liban">Liban</option>
                                            <option value="Lesotho">Lesotho</option>
                                            <option value="Liberia">Liberia</option>
                                            <option value="Libye">Libye</option>
                                            <option value="Liechtenstein">Liechtenstein</option>
                                            <option value="Lituanie">Lituanie</option>
                                            <option value="Luxembourg">Luxembourg</option>
                                            <option value="Macao">Macao</option>
                                            <option value="Macédoine">Macédoine, ancienne République de Yougoslavie</option>
                                            <option value="Madagascar">Madagascar</option>
                                            <option value="Malawi">Malawi</option>
                                            <option value="Malaisie">Malaisie</option>
                                            <option value="Maldives">Maldives</option>
                                            <option value="Mali">Mali</option>
                                            <option value="Malte">Malte</option>
                                            <option value="Marshall">Îles Marshall</option>
                                            <option value="Martinique">Martinique</option>
                                            <option value="Mauritanie">Mauritanie</option>
                                            <option value="Maurice">Maurice</option>
                                            <option value="Mayotte">Mayotte</option>
                                            <option value="Mexique">Mexique</option>
                                            <option value="Micronésie">Micronésie, États fédérés de Micronésie</option>
                                            <option value="Moldavie">Moldavie, République de Moldavie</option>
                                            <option value="Monaco">Monaco</option>
                                            <option value="Mongolie">Mongolie</option>
                                            <option value="Monténégro">Monténégro</option>
                                            <option value="Montserrat">Montserrat</option>
                                            <option value="Maroc">Maroc</option>
                                            <option value="Mozambique">Mozambique</option>
                                            <option value="Myanmar">Myanmar</option>
                                            <option value="Namibie">Namibie</option>
                                            <option value="Nauru">Nauru</option>
                                            <option value="Népal">Népal</option>
                                            <option value="Pays-Bas">Pays-Bas</option>
                                            <option value="Nouvelle-Calédonie">Nouvelle-Calédonie</option>
                                            <option value="Nouvelle-Zélande">Nouvelle-Zélande</option>
                                            <option value="Nicaragua">Nicaragua</option>
                                            <option value="Niger">Niger</option>
                                            <option value="Nigéria">Nigéria</option>
                                            <option value="Niue">Niue</option>
                                            <option value="Norfolk">Île Norfolk</option>
                                            <option value="Îles Mariannes du Nord">Îles Mariannes du Nord</option>
                                            <option value="Norvège">Norvège</option>
                                            <option value="Oman">Oman</option>
                                            <option value="Pakistan">Pakistan</option>
                                            <option value="Palau">Palau</option>
                                            <option value="PSE">Territoire palestinien occupé</option>
                                            <option value="Panama">Panama</option>
                                            <option value="Papouasie-Nouvelle-Guinée">Papouasie-Nouvelle-Guinée</option>
                                            <option value="Paraguay">Paraguay</option>
                                            <option value="Pérou">Pérou</option>
                                            <option value="Philippines">Philippines</option>
                                            <option value="Pitcairn">Pitcairn</option>
                                            <option value="Pologne">Pologne</option>
                                            <option value="Portugal">Portugal</option>
                                            <option value="Porto Rico">Porto Rico</option>
                                            <option value="Qatar">Qatar</option>
                                            <option value="Réunion">Réunion</option>
                                            <option value="Roumanie">Roumanie</option>
                                            <option value="Fédération de Russie">Fédération de Russie</option>
                                            <option value="Rwanda">Rwanda</option>
                                            <option value="Saint Barthélemy">Saint Barthélemy</option>
                                            <option value="SHN">Sainte-Hélène, Ascension et Tristan da Cunha</option>
                                            <option value="Saint-Kitts-et-Nevis">Saint-Kitts-et-Nevis</option>
                                            <option value="Sainte-Lucie">Sainte-Lucie</option>
                                            <option value="Saint-Martin">Saint-Martin (partie française)</option>
                                            <option value="aint-Pierre-et-Miquelon">Saint-Pierre-et-Miquelon</option>
                                            <option value="Saint-Vincent-et-les Grenadines">Saint-Vincent-et-les Grenadines</option>
                                            <option value="Samoa">Samoa</option>
                                            <option value="Saint-Marin">Saint-Marin</option>
                                            <option value="Sao Tomé-et-Principe">Sao Tomé-et-Principe</option>
                                            <option value="Arabie saoudite">Arabie saoudite</option>
                                            <option value="Sénégal">Sénégal</option>
                                            <option value="Serbie">Serbie</option>
                                            <option value="Seychelles">Seychelles</option>
                                            <option value="Sierra Leone">Sierra Leone</option>
                                            <option value="Singapour">Singapour</option>
                                            <option value="Sint Maarten (partie néerlandaise)">Sint Maarten (partie néerlandaise)</option>
                                            <option value="Slovaquie">Slovaquie</option>
                                            <option value="Slovénie">Slovénie</option>
                                            <option value="Salomon">Îles Salomon</option>
                                            <option value="Somalie">Somalie</option>
                                            <option value="Afrique du Sud">Afrique du Sud</option>
                                            <option value="Géorgie du Sud et îles Sandwich du Sud">Géorgie du Sud et îles Sandwich du Sud</option>
                                            <option value="Soudan du Sud">Soudan du Sud</option>
                                            <option value="Espagne">Espagne</option>
                                            <option value="Sri Lanka">Sri Lanka</option>
                                            <option value="Soudan">Soudan</option>
                                            <option value="Suriname">Suriname</option>
                                            <option value="Svalbard et Jan Mayen">Svalbard et Jan Mayen</option>
                                            <option value="Swaziland">Swaziland</option>
                                            <option value="Suède">Suède</option>
                                            <option value="Suisse">Suisse</option>
                                            <option value="République arabe syrienne">République arabe syrienne</option>
                                            <option value="Taïwan">Taïwan, province de Chine</option>
                                            <option value="Tadjikistan">Tadjikistan</option>
                                            <option value="Tanzanie">Tanzanie, République-Unie de Tanzanie</option>
                                            <option value="Thaïlande">Thaïlande</option>
                                            <option value="Timor-Leste">Timor-Leste</option>
                                            <option value="Togo">Togo</option>
                                            <option value="Tokelau">Tokelau</option>
                                            <option value="Tonga">Tonga</option>
                                            <option value="Trinité-et-Tobago">Trinité-et-Tobago</option>
                                            <option value="Tunisie">Tunisie</option>
                                            <option value="Turquie">Turquie</option>
                                            <option value="Turkménistan">Turkménistan</option>
                                            <option value="Îles Turques et Caïques">Îles Turques et Caïques</option>
                                            <option value="Tuvalu">Tuvalu</option>
                                            <option value="Ouganda">Ouganda</option>
                                            <option value="Ukraine">Ukraine</option>
                                            <option value="Émirats arabes unis">Émirats arabes unis</option>
                                            <option value="Royaume-Uni">Royaume-Uni</option>
                                            <option value="États-Unis">États-Unis</option>
                                            <option value="les mineures éloignées des États-Unis">Îles mineures éloignées des États-Unis</option>
                                            <option value="Uruguay">Uruguay</option>
                                            <option value="Ouzbékistan">Ouzbékistan</option>
                                            <option value="Vanuatu">Vanuatu</option>
                                            <option value="Venezuela">Venezuela, République bolivarienne</option>
                                            <option value="Viet Nam">Viet Nam</option>
                                            <option value="Îles Vierges britanniques">Îles Vierges britanniques</option>
                                            <option value="Îles Vierges américaines.">Îles Vierges américaines.</option>
                                            <option value="Wallis et Futuna">Wallis et Futuna</option>
                                            <option value="Sahara occidental">Sahara occidental</option>
                                            <option value="Yémen">Yémen</option>
                                            <option value="Zambie">Zambie</option>
                                            <option value="Zimbabwe">Zimbabwe</option>
                                        </select>
                                    </div>
                                </div>


                                <div className="row">
                                    <div className="col-12 mt-3">
                                        <label for="desc" class="form-label fw-bolder">Informations supplémentaires :<span class="text-danger">*</span></label>
                                        <textarea onChange={(handledInputData)} required class="form-control" id="desc" name="desc" ows="3"></textarea>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-12 col-md-4 mt-3">
                                        <label for="img1" class="form-label fw-bolder">Image 1 :</label>
                                        <input onChange={(event) => setDevis(devis => ({ ...devis, name: URL.createObjectURL(event.target.files[0]), file: event.target.files[0] }))} class="form-control" name="image" type="file" id="img1" />
                                    </div>

                                    <div className="col-sm-12 col-md-4 mt-3">
                                        <label for="img2" class="form-label fw-bolder">Image 2 :</label>
                                        <input onChange={(event) => setDevis(devis => ({ ...devis, name1: URL.createObjectURL(event.target.files[0]), file1: event.target.files[0] }))} class="form-control" name="image" type="file" id="img2" />
                                    </div>

                                    <div className="col-sm-12 col-md-4 mt-3">
                                        <label for="img3" class="form-label fw-bolder">Image 3 :</label>
                                        <input onChange={(event) => setDevis(devis => ({ ...devis, name2: URL.createObjectURL(event.target.files[0]), file2: event.target.files[0] }))} class="form-control" name="image" type="file" id="img3" />
                                    </div>


                                </div>

                            </div>


                            <div className="row">
                                <div className="col-12 mt-3">
                                    <button type="submit" class="btn btn-primary float-end">Envoyer</button>
                                </div>
                            </div>
                        </form> */}

                        <h2>
                            Obtenez rapidement un devis gratuit et sans engagement.
                        </h2>

                        <form onSubmit={handleSubmit} id="form">
                            <div class="mb-3">
                                <div className="row">
                                    <div className="col-sm-6 col-md-6 mt-3">
                                        <input onChange={handledInputData} type="text" placeholder="Nom" class="form-control" name="nom" id="nom" aria-describedby="emailHelp" required />
                                    </div>

                                    <div className="col-sm-6 col-md-6 mt-3">
                                        <input onChange={handledInputData} type="text" placeholder="Prénom" class="form-control" name="prenom" id="prenom" aria-describedby="emailHelp" required />
                                    </div>


                                </div>

                                <div className="row">
                                    <div className="col-sm-12 col-md-6 mt-3">
                                        <input onChange={handledInputData} type="text" placeholder="Téléphone" class="form-control" name="tel" id="tel" aria-describedby="emailHelp" required />
                                    </div>

                                    <div className="col-sm-12 col-md-6 mt-3">
                                        <input onChange={handledInputData} type="email" placeholder="Email" class="form-control" id="email" name="email" aria-describedby="emailHelp" required />
                                    </div>


                                </div>

                                <div className="row">
                                    <div className="col-sm-12 col-md-12 mt-3">
                                        <select onChange={handledInputData} required class="form-select" id="typeIntervention1" name='typeIntervention1'>
                                            <option value="">Choisir une intervention</option>
                                            <option value="Sleeve gastrique">Sleeve gastrique</option>
                                            <option value="Anneau gastrique">Anneau gastrique </option>
                                            <option value="By-pass gastrique">By-pass gastrique</option>
                                            <option value="Augmentation mammaire">Augmentation mammaire</option>
                                            <option value="Lifting des seins">Lifting des seins</option>
                                            <option value="Lipofilling mammaire">Lipofilling mammaire</option>
                                            <option value="Reduction mammaire">Reduction mammaire</option>
                                            <option value="Implant dentaire">Implant dentaire </option>
                                            <option value="Facette dentaire">Facette dentaire</option>
                                            <option value="Blachimment des dents">Blachimment des dents</option>
                                            <option value="Abdominoplastie">Abdominoplastie</option>
                                            <option value="Mini abdominoplastie">Mini abdominoplastie</option>
                                            <option value="Lifting des bras">Lifting des bras</option>
                                            <option value="Body lift">Body lift</option>
                                            <option value="Liposuccion">Liposuccion</option>
                                            <option value="Lifting des cuisses">Lifting des cuisses</option>
                                            <option value="Augmentation des mollets">Augmentation des mollets</option>
                                            <option value="Augmentation des fesses(implant)">Augmentation des fesses(implant)</option>
                                            <option value="Lipofilling des fesses">Lipofilling des fesses</option>
                                            <option value="Blepharoplastie">Blepharoplastie</option>
                                            <option value="Genioplastie">Genioplastie</option>
                                            <option value="Lifting visage">Lifting visage</option>
                                            <option value="Mini lifting visage">Mini lifting visage</option>
                                            <option value="Otoplastie">Otoplastie</option>
                                            <option value="Rhinoplastie">Rhinoplastie</option>
                                            <option value="Nymphoplastie">Nymphoplastie</option>
                                            <option value="Penoplastie">Penoplastie</option>
                                            <option value="Vaginoplastie">Vaginoplastie</option>
                                            <option value="Biopsie testiculaire">Biopsie testiculaire</option>
                                            <option value="Fecondation in vitro">Fecondation in vitro</option>
                                            <option value="Insemination artificielle">Insemination artificielle</option>
                                            <option value="Greffe cheveux">Greffe cheveux</option>
                                            <option value="Greffe de la barbe">Greffe de la barbe</option>
                                            <option value="Greffe des sourcils">Greffe des sourcils</option>
                                            <option value="Traitement Laser">Traitement Laser</option>
                                            <option value="Mesobotox">Mesobotox</option>
                                            <option value="PRP">PRP</option>
                                            <option value="Rhinoplastie Médicale">Rhinoplastie Médicale</option>
                                            <option value="Amincissement">Amincissement</option>
                                            <option value="Fils Tenseurs">Fils Tenseurs</option>
                                            <option value="Mésothérapie">Mésothérapie</option>
                                            <option value="Toxine Botulique">Toxine Botulique</option>
                                        </select>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-12 mt-3">
                                        <textarea onChange={(handledInputData)} required class="form-control" id="desc" placeholder="Informations supplémentaires" name="desc" ows="1"></textarea>
                                    </div>
                                </div>
                            </div>

                            <div className="row ">
                                <div className="col-12 mt-3 d-flex justify-content-center">
                                    <button type="submit" class="btn btn-primary ps-5 pe-5">Envoyer</button>
                                </div>
                            </div>
                        </form>


                        {
                            !alertDanger || <div id="alert" class="alert alert-danger alert-dismissible fade show mt-3" role="alert">
                                Une erreur se produite
                                <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                            </div>
                        }

                    </div>
                </div>

            </Layout>
        </>
    )
}

export default DevisPage
